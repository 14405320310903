import React from "react"
import { graphql } from "gatsby"
import InnerHero from "../components/partials/inner-hero"
import Layout from "../components/layouts/inner"
import SEO from "../components/seo"
import { ModalProvider } from "../components/modules/modal-context"
import { FormProvider } from "../components/modules/popup-form-context"
import PopupForm from "../components/modules/popup-form"
import TopInnerContent from "../components/modules/top-inner-content"

export default ({ data }) => {
  //highlight-line
  const page = data.wpPage
  const posts = data.allWpPost.nodes
  const robots = {
    name: `robots`,
    content: `${page.seo.metaRobotsNoindex}, ${page.seo.metaRobotsNofollow}`,
  }
  const listPages = pages => {
    let str = "<ul>"
    pages.forEach(page => {
      str += `<li><a href="${page.uri}">${page.title}</a>`
      if (page.wpChildren?.nodes.length > 0)
        str += listPages(page.wpChildren.nodes)
      else if (page.title === "Blog") {
        str += listPages(posts)
      }
      str += `</li>`
    })
    return str + "</ul>"
  }
  return (
    <FormProvider>
      <ModalProvider>
        <Layout spanish={false}>
          <SEO
            title={page.seo.title}
            description={page.seo.metaDesc}
            meta={[robots]}
            schema={page.seo.schema?.raw}
          />
          <PopupForm />
          <InnerHero videoContent={page.videoContent} />
          <TopInnerContent sitemap>
            {"<h1>Sitemap</h1>" + listPages(data.allWpPage.nodes)}
          </TopInnerContent>
        </Layout>
      </ModalProvider>
    </FormProvider>
  )
}

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      content
      seo {
        ...SEO
      }
      uri
      videoContent {
        ...VideoContent
      }
      ...Page
    }
    allWpPage(filter: { parentId: { eq: null } }) {
      nodes {
        ...Page
        ...ChildPages
        ...GrandChildPages
      }
    }
    allWpPost {
      nodes {
        ...Post
      }
    }
  }

  fragment Page on WpPage {
    title
    uri
  }

  fragment Post on WpPost {
    title
    uri
  }

  fragment ChildPages on WpPage {
    wpChildren {
      nodes {
        ... on WpPage {
          ...Page
        }
      }
    }
  }

  fragment GrandChildPages on WpPage {
    wpChildren {
      nodes {
        ...ChildPages
      }
    }
  }
`
